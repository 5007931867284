export const environment = {
  production: true,
  version: "2.2.4.0-20241126-qa",
  test: "qa",
  googleAnalytics: "G-L2G7FL9MP3",
  googleAnalyticsTechnical: null,
  rol: "",
  expressUserData: [],
  MultiCountryData: [],
  basePath: "/",
  rediretPath: "/design/",
  apigoogle:"AIzaSyBLMW-yZQI4A_mnrDCugdzhMl1RPLjrJ4w",
  storeLink: 'https://qa.tienda.forzadelivery.com',
};

export enum Constants {
  // api desarrollo
  api = "https://qa.portal.forzadelivery.com/fd/Home.aspx/API",
  sesionTime = 900000, // 15 * 60 * 1000 = 900000 milisegundos = 15 minutos
  sesionTimeExpress = 32400000// 9 horas * 60 minutos  * 60 segundos * 1000 milisegundos = 32400000
}
